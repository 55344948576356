<template>
  <div class="list-info">
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入关键字(多个条件请用空格隔开)" class="search-content"
             @keyup.enter="onSearch">
      <img :src="require('@/assets/img/search.png')" class="search-icon">
      <!-- <div class="search-divider"></div> -->
      <img v-if="searchValue" :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <!-- <div class="search-btn" @click="onSearch">搜索</div> -->
    </div>
    <div class="devied"></div>
    <!-- <div class="search-result" v-if="showSearchKey">
      <div v-for="item in searchKeyList" :key="item" style="margin-bottom: 10px">{{item}}</div>
    </div> -->
    <template v-if="searchType == 1 || searchType == 2">
      <div class="result-info">
        <div class="lh24" v-if="searchType == 1">
          结果共涉及 <span :style="{'text-decoration':resultInfo.resCount.num ? 'underline' : ''}"
                      @click="resultInfo.resCount.num ? toRes() : ''">{{ resultInfo.resCount.num }}</span> 个居民，其中
          <span v-for="(item, index) in resultInfo.registryType" :key="'parent-' + item.value">
            <span style="color:#4581F8" @click="searchClick(item)">{{ item.label }}</span> <span
              :style="{'text-decoration':item.num ? 'underline' : ''}"
              @click="item.num ? toRes(item) : ''">{{ item.num }}</span> 人<span v-if="item.children">（包括
            <span v-for="(val, i) in item.children" :key="'son-' + val.value">
              <span style="color:#4581F8" @click="searchClick(val)">{{ val.label }}</span> <span
                :style="{'text-decoration':val.num ? 'underline' : ''}"
                @click="val.num ? toRes(val) : ''">{{ val.num }}</span> 人<span
                v-if="i != item.children.length - 1">，</span>
            </span>）<span v-if="index != resultInfo.registryType.length - 1">，</span></span>
          </span>
        </div>
        <div class="lh24" v-if="searchType == 2">
          结果共涉及
          <template v-if="resultInfo.subCount"><span>{{ resultInfo.subCount.num }}</span> 个小区，</template>
          <template v-if="resultInfo.buildCount"><span>{{ resultInfo.buildCount }}</span> 个楼幢，</template>
          <template v-if="resultInfo.unitCount"><span>{{ resultInfo.unitCount }}</span> 个单元，</template>
          <span :style="{'text-decoration':resultInfo.house.num ? 'underline' : ''}"
                @click="resultInfo.house.num ? toHouse() : ''">{{ resultInfo.house.num }}</span> 套房屋（其中
          <span v-for="(val, i) in resultInfo.house.children" :key="val.value">
            <span style="color:#4581F8" @click="searchClick(val)">{{ val.label }}</span> <span
              :style="{'text-decoration':val.num ? 'underline' : ''}"
              @click="val.num ? toHouse({useType: val.value, attrValue: val.label}) : ''">{{ val.num }}</span> 套<span
              v-if="i != resultInfo.house.children.length - 1">，</span>
          </span>）
          <template v-if="resultInfo.resCount.num > 0">
            <span>，</span>
            <span :style="{'text-decoration':resultInfo.resCount.num ? 'underline' : ''}"
                  @click="resultInfo.resCount.num ? toRes() : ''">{{ resultInfo.resCount.num }}</span> 个居民，其中
            <span v-for="(item, index) in resultInfo.registryType" :key="'parent-' + item.value">
              <span style="color:#4581F8" @click="searchClick(item)">{{ item.label }}</span> <span
                :style="{'text-decoration':item.num ? 'underline' : ''}"
                @click="item.num ? toRes(item) : ''">{{ item.num }}</span> 人<span v-if="item.children">（包括
              <span v-for="(val, i) in item.children" :key="'son-' + val.value">
                <span style="color:#4581F8" @click="searchClick(val)">{{ val.label }}</span> <span
                  :style="{'text-decoration':val.num ? 'underline' : ''}"
                  @click="val.num ? toRes(val) : ''">{{ val.num }}</span> 人<span
                  v-if="i != item.children.length - 1">，</span>
              </span>）<span v-if="index != resultInfo.registryType.length - 1">，</span></span>
            </span>
          </template>
        </div>
      </div>
      <div v-if="resultInfo.labels" class="result-info">
        <div style="color: #333; margin-bottom: 6px">标签情况</div>
        <div v-for="item in resultInfo.labels" :key="item.value">
          <span style="color:#4581F8" @click="searchClick(item)">{{ item.label }}</span> 共有 <span
            :style="{'text-decoration':item.num ? 'underline' : ''}"
            @click="item.num ? toRes(item) : ''">{{ item.num }}</span> 人，
          <template v-if="item.children && item.children.length > 0">其中
            <span v-for="(val, i) in item.children" :key="val.value">
            <span style="color:#4581F8" @click="searchClick(val)">{{ val.label }}</span> <span
                :style="{'text-decoration':val.num ? 'underline' : ''}"
                @click="val.num ? toRes(val) : ''">{{ val.num }}</span> 人<span
                v-if="i != item.children.length - 1">，</span>
          </span></template>
        </div>
      </div>
      <div v-if="resultInfo.age" class="result-info">
        <div style="color: #333; margin-bottom: 6px">年龄情况</div>
        <div v-for="item in resultInfo.age" :key="item.value">
          <span style="color:#4581F8" @click="searchClick(item)">{{ item.label }}</span> <span
            :style="{'text-decoration':item.num ? 'underline' : ''}"
            @click="item.num ? toRes(item) : ''">{{ item.num }}</span> 人
        </div>
      </div>
      <div v-if="resultInfo.check" class="result-info">
        <div style="color: #333; margin-bottom: 6px">核查情况</div>
        <div v-for="item in resultInfo.check" :key="item.value">
          <span style="color:#4581F8" @click="searchClick(item)">{{ item.label }}</span> <span
            :style="{'text-decoration':item.num ? 'underline' : ''}"
            @click="item.num ? toRes(item) : ''">{{ item.num }}</span> 人
        </div>
      </div>
      <div class="result-info" v-if="resultInfo.sub">
        <div style="color: #333; margin-bottom: 6px">可继续筛选：</div>
        <!-- <div v-if="resultInfo.com">
          社区：<span v-for="item in resultInfo.com" :key="item.value" style="color:#4581F8;margin-right: 10px;word-break:break-all" @click="searchClick(item)">{{item.label}}</span>
        </div> -->
        <div>
          小区：<span v-for="item in resultInfo.sub" :key="item.value"
                   style="color:#4581F8;margin-right: 10px;word-break:break-all"
                   @click="searchClick(item)">{{ item.label }}</span>
        </div>
      </div>
    </template>
    <template v-if="searchType == 3">
      <div class="result-info">
        <div class="link-btn" @click="toHouseInfo">跳转</div>
        <div style="color: #4E7CF5">{{ resultInfo.only_fullName.label }}<span
            style="color: #333">：共{{ resultInfo.only_resCount.num }}人</span></div>
        <div>面积：{{ resultInfo.only_areas.label }}</div>
        <div>标签：{{ resultInfo.only_labels.label || '无' }}</div>
        <div>房屋属性：{{ resultInfo.only_useType.label }}</div>
      </div>
    </template>
    <!-- 全局拍照搜索车辆结果 -->
    <template v-if="searchType == 4">
      <div class="car-res" v-for="item in carList" :key="item.id">
        <div class="top">
          <div>{{item.communityStr}}</div>
          <div>{{item.subareaStr}}</div>
        </div>
        <div class="mid">
          <div class="mid-first">
            <div class="mid-first-left">{{item.suserName}}</div>
            <div class="mid-first-right">
              {{item.mobile}}
              <a :href="'tel:'+item.mobile"><img class="mobile" :src="require('@/assets/img/mobile.png')" alt=""></a>
            </div>
          </div>
          <div class="mid-second">
            <div class="mid-second-text">
              <div>车辆品牌：<span>{{item.brand}}</span></div>
            </div>
            <div class="mid-second-text">
              <div>车身颜色：<span>{{item.colorStr}}</span></div>
            </div>
          </div>
          <div class="mid-second">
            <div class="mid-second-text">
              <div>车牌类型：<span>{{item.licencePlateTypeStr}}</span></div>
            </div>
            <div class="mid-second-text">
              <div>车牌颜色：<span>{{item.licencePlateColorStr}}</span></div>
            </div>
          </div>
          <div class="mid-third">
            <div class="mid-third-item">{{item.carNumber}}</div>
            <div class="mid-third-item">{{item.typeStr}}</div>
          </div>
        </div>
        <div class="down">
          居住地区：<span>{{item.fullName}}</span>
        </div>
        <div class="devied"></div>
      </div>
    </template>
    <!-- 全局拍照搜索企业结果 -->
    <template v-if="searchType == 5">
      <div class="place-res" v-for="item in placeList" :key="item.id">
        <div class="top">
          <div class="top-left">{{item.communityName}}</div>
          <div v-if="item.placeType" class="top-right">
            {{item.placeType == 1 ? '场所' : item.placeType == 2 ? '沿街店铺' : item.placeType == 3 ? '企业' : ''}}
          </div>
          <div v-if="item.shopType" class="top-right">
            {{item.shopType == 1 ? '个体工商户' : item.shopType == 2 ? '企业' : ''}}
          </div>
        </div>
        <div class="mid">
          <van-image :src="item.url||require('@/assets/img/default.png')" class="mid-left">
            <template slot="error">
              <img :src="require('@/assets/img/default.png')" alt="" class="mid-left">
            </template>
            <template slot="loading">
              <img :src="require('@/assets/img/default.png')" alt="" class="mid-left">
            </template>
          </van-image>
          <!-- <img class="mid-left" :src="require('@/assets/img/default.png')" /> -->
          <div class="mid-right">
            <div class="mid-right-item">
              <div class="name">{{item.contact}}</div>
              <div class="right">
                <span>{{item.contactMobile}}</span>
                <a :href="'tel:'+item.contactMobile"><img class="mobile" :src="require('@/assets/img/mobile.png')" alt=""></a>
              </div>
            </div>
            <div class="mid-right-item" style="margin: 10px 0;">
              <div class="left">{{item.name}}</div>
              <i class="right el-icon-arrow-right"></i>
            </div>
            <div class="mid-right-item">
              <div class="buss-id">营业执照：<span style="color: #333333;">{{item.licenseCode}}</span></div>
            </div>
          </div>
        </div>
        <div class="down">
          地址：<span>{{item.address}}</span>
        </div>
        <div class="devied"></div>
      </div>
    </template>

    <div class="res-info" v-for="(item, index) in resList" :key="index"
         @click="goInfo(item.id, item.name, item.idNumber)">
      <div class="res-top">
        <span
            style="font-weight:bold;color:333;overflow: hidden;text-overflow: ellipsis;
            white-space: nowrap;width: 180px;display: inline-block;">{{
            item.houses
          }}</span>
        <div class="res-type">{{ item.registryTypeName }}</div>
        <a :href="'tel:' + item.mobile" @click.stop="" v-if="item.mobile" class="tel">
          <img src="@/assets/img/phone2.png" alt="" width="12"/>
        </a>
      </div>
      <div class="content-top">
        <img src="@/assets/img/headImg.png" alt="" width="40" height="40">
        <div class="top-info">
          <div style="color:#333">{{ item.name }}</div>
          <div>{{ item.sexName }} {{ item.age }} {{ item.mobile }}</div>
          <div>{{ item.idNumber }}</div>
        </div>
        <van-icon v-if="item.notCheckField && item.notCheckField.length > 0" @click.stop="dataCheck(item)"
                  name="warning" size="24" color="#F99C11" class="check-icon"/>
        <van-icon name="arrow"/>
      </div>
      <div class="content-bottom">
        <div style="display:flex;justify-content:space-between">
          <div>最近核酸时间：<span style="color:#333">{{ item.userCorrelationVo.nucleicAcidTime || '暂无' }}</span></div>
          <div>疫苗针次：<span style="color:#333">{{ item.userCorrelationVo.vaccinationTimes }}</span></div>
        </div>
        <div style="display:flex;justify-content:space-between">
          <div>活动：<span style="color:#333">{{ item.userCorrelationVo.activityNum }}次</span></div>
          <div>走访：<span style="color:#333">{{ item.userCorrelationVo.visitNum }}次</span></div>
          <div>问题：<span style="color:#333">{{ item.userCorrelationVo.problemNum }}次</span></div>
          <div>报修：<span style="color:#333">{{ item.userCorrelationVo.repairNum }}次</span></div>
        </div>
      </div>
      <div>
        <van-row gutter="12">
          <van-col :span="8">
            <div class="func-btn" @click.stop="moveOut(item.id)">迁出</div>
          </van-col>
          <van-col :span="8">
            <div class="func-btn" @click.stop="goVisit(item)">走访</div>
          </van-col>
          <van-col :span="8">
            <div class="func-btn" @click.stop="goUpdate(item.id)">修改</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-empty description="暂无搜索结果" v-if="noResult"/>
  </div>

</template>
<script>
import resList from "@/views/visit/resList";
import {getImageStream} from "@/utils/index";

export default {
  name: 'search-result',
  data() {
    return {
      noResult: false,
      searchValue: '',
      searchValueList: [],
      showSearchKey: false,
      // searchKeyList: ['走访1', '走访22', '走访333', '走访4444'],
      resultInfo: {},
      searchInfo: null,
      searchType: 0,
      infoNum: 1000,
      resList: [],
      carList: [],
      placeList: [],
      globalSearchType: null,
    }
  },
  created() {
    this.searchValue = this.$globalData.searchValue
    this.globalSearchType = this.$globalData.searchType
    this.searchValueList = this.searchValue.split(' ').filter(item => {
      return item
    }).map(item => {
      return {
        label: item,
        type: this.globalSearchType ? this.globalSearchType : 'unknown',
      }
    })
    this.onSearch()
  },
  methods: {
    toRes(val) {
      let searchInfo = JSON.stringify(this.searchInfo)
      let searchVal = ''
      if (val) {
        searchVal = JSON.stringify(val)
      }
      this.$router.push({path: '/userRes-index',
        query: {searchInfo:  encodeURIComponent(searchInfo), searchVal: encodeURIComponent(searchVal)}})
    },
    toHouse(val) {
      this.$router.push({path: '/house', query: {...this.searchInfo, ...val}})
    },
    onSearch() {
      this.resultInfo = {}
      this.searchInfo = null
      this.searchType = 0
      let searchValue = this.searchValue.split(' ').filter(item => {
        return item
      })
      this.searchValueList = this.searchValueList.filter(item => {
        return searchValue.indexOf(item.label) > -1
      })
      searchValue.map(item => {
        let searchValueList = this.searchValueList.map(item => {
          return item.label
        })
        if (searchValueList.indexOf(item) == -1) {
          this.searchValueList.push({
            label: item,
            type: 'unknown'
          })
        }
      })
      this.noResult = false
      this.$toast.loading({
        duration: 0,
        message: '搜索中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/home/globalSearch"),
        method: "post",
        data: this.$http.adornData(this.searchValueList, false),
      }).then(({data}) => {
        if (data.code == 0) {
          if(data.globalSearch.type == 4) {
            this.searchType = data.globalSearch.type
            this.carList = data.globalSearch.list
          }else if(data.globalSearch.type == 5) {
            data.globalSearch.list.forEach((item) => {
              if (item.imgUrl && item.imgUrl.length != 0) {
                let urls = item.imgUrl.split(",");
                let url = getImageStream(urls[0]);
                item["url"] = url;
              }
            });
            this.searchType = data.globalSearch.type
            this.placeList = data.globalSearch.list
          } else if (data.globalSearch && data.globalSearch.statistics) {
            if (data.globalSearch.statistics.registryType) {
              data.globalSearch.statistics.registryType = data.globalSearch.statistics.registryType.filter(item => {
                return item.num != 0
              })
            }
            this.resultInfo = data.globalSearch.statistics
            console.log(this.resultInfo.registryType)
            this.searchInfo = data.globalSearch.condition
            this.searchType = data.globalSearch.type
            this.resList = data.globalSearch.list
          } else {
            this.resList = []
            this.noResult = true
          }
          this.$toast.clear()
        } else {
          this.noResult = true
          this.$toast.clear()
          this.$toast.fail({message: data.msg})
        }
      })
    },
    onCancel() {
      this.searchValue = ''
      this.searchValueList = []
    },
    toHouseInfo() {
      this.$store.commit("setHouseId", this.searchInfo.houseId);
      this.$router.push({
        path: "/house-info",
        query: {id: this.searchInfo.houseId},
      });
    },
    searchClick(item) {
      // 过滤重复添加关键词
      if ((' ' + this.searchValue + ' ').indexOf(' ' + item.label + ' ') == -1) {
        this.searchValue += this.searchValue ? (' ' + item.label) : item.label
        this.searchValueList.push({
          value: item.value,
          label: item.label,
          type: item.type
        })
        this.onSearch()
      }
    },
    dataCheck(item) {
      let msg = item.checkList.filter(item => item != '').map((item, index) => {
        return `<p>${index + 1}.${item}</p>`
      }).join('')
      this.$dialog.confirm({
        title: '错误信息',
        center: true,
        message: msg,
        showCancelButton: true,
        confirmButtonText: '去处理',
        cancelButtonText: '关闭',
        confirmButtonColor: '#ed4747'
      }).then(() => {
        this.$router.push({
          path: '/userRes-dataCheck',
          query: {data: JSON.stringify(item.notCheckField), userId: item.id, resId: item.resId}
        })
      })
    },
    moveOut(e) {
      this.$dialog.confirm({
        message: '确认迁出？',
      }).then(() => {
        //户籍性质
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/moveOutCommunity`),
          method: 'get',
          params: this.$http.adornParams({
            id: parseInt(e),
            community: this.$globalData.userInfo.orgId
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success({
              message: '迁出成功',
              onOpened: () => {
                setTimeout(() => {
                  for (let i = 0; i < this.resList.length; i++) {
                    if (resList[i].id == e) {
                      resList.splice(i, 1)
                      break
                    }
                  }
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    goVisit(item) {
      let houseId = null
      if (item.houseList) {
        houseId = Number(item.houseList[1].id)
      }
      this.$router.push({path: '/visit-add', query: {houseId: houseId, userId: item.id, userName: item.name, fromPage: 'searchResult'}})
    },
    goUpdate(id) {
      this.$router.push({path: '/userRes-info', query: {userId: id}})
    },
    goInfo(id, name, idNumber) {
      this.$router.push({path: '/result-info', query: {id, name, idNumber}})
    }
  },
}
</script>
<style scoped lang="scss">
.list-info {
  // padding-bottom: 24px;
  .search {
    // margin: 24px auto 0;
    position: relative;
    display: flex;
    align-items: center;
    height: 112px;
    background: #fff;
    // border-radius: 20px;
    padding: 0 30px;
    .search-content {
      width: 690px;
      height: 64px;
      background: rgba(0,0,0,0.04);
      border-radius: 8px;
      // border: none;
      width: 100%;
      padding-left: 60px;
      // padding: 0 166px 0 106px;
    }

    .search-btn {
      width: 112px;
      height: 56px;
      line-height: 56px;
      border-radius: 20px;
      position: absolute;
      right: 4px;
      top: 4px;
    }

    .search-icon {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 38px;
      left: 42px;
      // top: 50%;
      // transform: translateY(-50%);
    }

    .search-clear {
      width: 28px;
      height: 28px;
      right: 140px;
      top: 40px;
      right: 50px;
      // transform: translateY(-50%);
    }

    .search-divider {
      width: 2px;
      height: 32px;
      background: #DBDBDB;
      border-radius: 2px;
      position: absolute;
      left: 80px;
      top: 16px;
    }
  }
  .devied {
    width: 100%;
    height: 24px;
    background: #F5F5F5;
  }
}

.search-result {
  box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
  color: #666;
  z-index: 999;
  font-size: 24px;
  box-sizing: border-box;
  padding: 20px 24px 0;
  position: absolute;
  margin-top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 690px;
  border-radius: 20px;
  background-color: #fff;
}

.result-info {
  box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
  color: #666;
  font-size: 28px;
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  margin-top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 690px;
  border-radius: 20px;
  background-color: #fff;

  div {
    line-height: 48px;
  }

  .link-btn {
    width: 88px;
    line-height: 48px;
    color: #4E7CF5;
    border: 1px solid #4E7CF5;
    border-radius: 8px;
    text-align: center;
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 24px;
  }
}

.res-info {
  box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
  color: #666;
  font-size: 28px;
  box-sizing: border-box;
  padding: 0 24px;
  position: relative;
  margin-top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 690px;
  border-radius: 20px;
  background-color: #fff;

  .res-top {
    height: 96px;
    line-height: 96px;
    position: relative;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

    .res-type {
      position: absolute;
      top: 20px;
      right: 88px;
      background: rgba(78, 124, 245, 0.12);
      color: #4E7CF5;
      border-radius: 12px;
      height: 52px;
      line-height: 52px;
      padding: 0 32px;
      font-size: 24px;
    }

    .tel {
      position: absolute;
      right: 24px;
      top: -4px;
    }
  }

  .content-top {
    height: 192px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-info {
      position: absolute;
      left: 136px;
      color: #666;

      div {
        line-height: 48px;
      }
    }

    .check-icon {
      position: absolute;
      right: 75px;
    }
  }

  .content-bottom {
    color: #666;
    font-size: 24px;
    height: 140px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .func-btn {
    margin: 24px 0;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background: #4E7CF5;
    color: #fff;
    font-size: 24px;
    border-radius: 12px;
  }
}
.car-res {
  // padding: 0 30px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    font-size: 28px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    margin: 0 30px;
  }
  .mid {
    padding: 0 30px;
    .mid-first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 28px;
      font-family: 'PingFang Bold';
      font-weight: 600;
      margin: 12px 0;
      .mid-first-left {
        font-family: 'PingFangSC-Regular';
        font-weight: 600;
        color: #333333;
      }
      .mid-first-right {
        color: #4581F8;
        .mobile {
          width: 40px;
          height: 40px;
          margin-left: 20px;
        }
      }
    }
    .mid-second {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 540px;
      margin-top: 24px;
      font-size: 28px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
      overflow: hidden;
      .mid-second-text {
        span {
          font-weight: 600;
          color: #333333;
        }
      }
    }
    .mid-third {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin: 24px 0;
      .mid-third-item {
        height: 52px;
        background: rgba(78,124,245,0.12);
        border-radius: 8px;
        line-height: 52px;
        padding: 0 20px;
        font-size: 24px;
        font-family: 'PingFang Bold';
        font-weight: 600;
        color: #4E7CF5;
        margin-right: 24px;
      }
      
    }
  }
  .down {
    height: 86px;
    line-height: 86px;
    font-size: 28px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
    padding: 0 30px;
    border-top: 2px solid rgba(0,0,0,0.1);
    span {
      font-weight: 600;
      color: #333333;
    }
  }
  .devied {
    width: 100%;
    height: 16px;
    background: #F5F5F5;
  }
}
.place-res {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    margin: 0 30px;
    .top-left {
      font-size: 28px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
    }
    .top-right {
      height: 52px;
      background: rgba(78,124,245,0.12);
      border-radius: 8px;
      font-size: 24px;
      font-family: 'PingFang Bold';
      font-weight: 500;
      color: #4E7CF5;
      line-height: 52px;
      padding: 0 20px;
    }
  }
  .mid {
    margin: 0 30px;
    display: flex;
    align-items: flex-start;
    padding: 24px 0;
    .mid-left {
      width: 160px;
      height: 160px;
      margin-right: 16px;
    }
    .mid-right {
      height: 160px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .mid-right-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 28px;
          font-family: 'PingFangSC-Regular';
          font-weight: 600;
          color: #333333;
        }
        .left {
          font-size: 28px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: #333333;
        }
        .right {
          font-size: 40px;
          span {
            font-size: 24px;
            font-family: 'PingFangSC-Regular';
            font-weight: 400;
            color: #4581F8;
          }
          .mobile {
            width: 40px;
            height: 40px;
            margin-left: 40px;
          }
        }
        .buss-id {
          font-size: 28px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
  .down {
    margin: 0 30px;
    border-top: 2px solid rgba(0,0,0,0.1);
    height: 90px;
    line-height: 90px;
  }
  .devied {
    width: 100%;
    height: 16px;
    background: #F5F5F5;
  }
}
</style>
